import { OlliePipeEvent, OlliePipeMeta } from "@ollie-sports/models";

export type SavedEvent = { event: OlliePipeEvent; meta: OlliePipeMeta };

export function scrubEvent(event: OlliePipeEvent) {
  const stringifiedPayload = JSON.stringify(event.payload);
  if (stringifiedPayload.length > 10_000) {
    return {
      ...event,
      payload: stringifiedPayload.slice(0, 10_000)
    };
  }

  return {
    ...event,
    payload: prettifyErrorsInPlace(event.payload)
  };
}

function prettifyError(a: Error) {
  const obj: any = {};
  Object.getOwnPropertyNames(a).forEach(k => {
    const val = (a as any)[k];
    if (val) {
      obj[k] = val;
    }
  });

  return obj;
}

function prettifyErrorsInPlace(obj) {
  if (obj instanceof Error) {
    return prettifyError(obj);
  }

  function traverse(current) {
    // Check if the current item is an object (and not null) or an array
    if (current !== null && typeof current === "object") {
      // Iterate over the properties or indices of the object or array
      for (let key in current) {
        if (current.hasOwnProperty(key)) {
          if (current[key] instanceof Error) {
            current[key] = prettifyError(current[key]);
          }

          traverse(current[key]);
        }
      }
    }
  }

  traverse(obj);

  return obj;
}
