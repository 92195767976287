//Web/Node.js Storage
import { SavedEvent, scrubEvent } from "./utils";

const STORAGE_KEY = `@ollie-pipe-saved-events`;

const isNodeEnv = typeof process === "object" && !!process && !!process?.versions?.node;
let hasInitialized = isNodeEnv;
let curr: SavedEvent[] = [];

export class Storage {
  static async addEvents(savedEvents: SavedEvent[]): Promise<void> {
    await new Promise(res => setTimeout(res, 0));

    ensureInitialized();

    const scrubbedEvents = savedEvents.map(a => {
      return {
        ...a,
        event: scrubEvent(a.event)
      };
    });

    curr.push(...scrubbedEvents);
    if (!isNodeEnv) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(curr));
    }
  }

  static async popEvents(): Promise<SavedEvent[]> {
    await new Promise(res => setTimeout(res, 0));
    ensureInitialized();

    const events = curr;
    if (!isNodeEnv) {
      localStorage.removeItem(STORAGE_KEY);
    }
    curr = [];

    return events;
  }
}

function ensureInitialized() {
  if (hasInitialized || isNodeEnv) {
    return;
  }

  try {
    curr = JSON.parse(localStorage.getItem(STORAGE_KEY) || "[]");
  } catch (e) {
    curr = [];
  }

  hasInitialized = true;
}
