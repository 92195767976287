import axios, { AxiosError } from "axios";
import { OlliePipeEvent, OlliePipeMeta } from "@ollie-sports/models";
import _ from "lodash";
import { Storage } from "./storage";
import { SavedEvent, scrubEvent } from "./utils";

const isNodeEnv = typeof process === "object" && !!process && !!process?.versions?.node;

interface Config {
  disabled?: boolean;
  getBestGuessAccountId: () => Promise<string>;
  saveEventsDirectlyToDB?: (a: SavedEvent[]) => Promise<void>;
  os?: string;
  deviceId?: string;
  enableVerboseErrorLogging: boolean;
  source: OlliePipeMeta["source"];
  pipeUrl: string;
  pipeFallBackUrlFirestore: string; // Example "https://firestore.googleapis.com/v1/projects/ollie-admin-prod/databases/(default)/documents/backupPipe"
  session_id: string;
  git: string;
}

export class OlliePipe {
  constructor(private config: Config) {
    if (isNodeEnv) {
      process?.on("SIGINT", async () => {
        await this.debouncedWriteSavedToServer.flush();
        process.exit(0);
      });
    }
  }

  private debouncedWriteSavedToServer = _.debounce(async () => {
    const events = await Storage.popEvents();
    if (!events || !events.length) {
      return;
    }

    try {
      if (this.config.saveEventsDirectlyToDB) {
        try {
          await this.config.saveEventsDirectlyToDB(events);
          return; //Early return since direct DB write worked.
        } catch (e) {
          console.error("Unable to save events directly to DB!");
          console.error(e);
          //Do nothing... Continue on to the url based approach
        }
      }

      const meta = events[0].meta;
      await axios.post(this.config.pipeUrl, { meta, events: events.map(a => a.event) }).catch(async () => {
        await new Promise(res => setTimeout(res, 1000));
        return await axios.post(this.config.pipeUrl, events);
      });
    } catch (e) {
      console.error("Problem sending pipe events!", e);
      await Storage.addEvents(events);
    }
  }, 3000);

  public async emitEvent(
    p: OlliePipeEvent | string,
    options?: { sendImmediate?: boolean; disableDevTerminalLogging?: true; accountId?: string }
  ) {
    if (this.config.disabled) {
      return;
    }

    let pendingEvent: OlliePipeEvent;
    if (typeof p === "string") {
      pendingEvent = { type: p, payload: {} };
    } else {
      pendingEvent = scrubEvent({ type: p.type, payload: p.payload });
    }

    if (this.config.enableVerboseErrorLogging && !options?.disableDevTerminalLogging && pendingEvent.type.match("error-")) {
      try {
        let valToLog: any;

        if (typeof p !== "string" && p.payload instanceof Error) {
          if ((p.payload as any).isAxiosError) {
            const err: AxiosError = p.payload as any;
            valToLog = new Error(err.message);
            valToLog.stack = err.stack;
            valToLog.request = {
              requestUrl: err.config.url,
              requestData: err.config.data
            };
            valToLog.response = {
              responseStatus: err.response.status,
              responseText: err.response.statusText,
              responseData: err.response.data ? JSON.stringify(err.response.data, null, 2) : undefined
            };
          } else {
            valToLog = p.payload;
          }
        } else {
          valToLog = JSON.stringify(pendingEvent, null, 2).slice(0, 1500);
        }

        console.error(valToLog);
      } catch (e) {
        console.info(pendingEvent);
      }
    }

    const meta = await this.getMeta({ account_id: options?.accountId });

    await Storage.addEvents([{ event: pendingEvent, meta }]);

    if (options && options.sendImmediate) {
      this.debouncedWriteSavedToServer.flush();
    } else {
      this.debouncedWriteSavedToServer();
    }
  }

  private async getMeta(override?: { account_id?: string }): Promise<OlliePipeMeta> {
    const account_id = override?.account_id || (await this.config.getBestGuessAccountId());

    return {
      account_id,
      session_id: this.config.session_id,
      source: this.config.source,
      os: this.config.os || "UNKNOWN",
      deviceId: this.config.deviceId || "UNKNOWN",
      date_ms: Date.now(),
      git: this.config.git || "UNKNOWN"
    };
  }
}
